@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .perspective-1000 {
    perspective: 1000px;
  }

  .backface-hidden {
    backface-visibility: hidden;
  }

  .transform-style-3d {
    transform-style: preserve-3d;
  }

  .rotate-y-180 {
    transform: rotateY(180deg);
  }
}

.font-league-spartan {
  font-family: "League Spartan", sans-serif;
}
